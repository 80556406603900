import type {CpqRatePlanType} from '@shared/bff--moons/generated/beyond'

// Order matters! The order of the plan types here determines which plans are considered higher than others
export const planTypes = ['STARTER', 'ESSENTIAL', 'ADVANCED', 'BEYOND'] satisfies CpqRatePlanType[]

export const getHighestPlan = (inputPlanTypes: CpqRatePlanType[]): CpqRatePlanType | null => {
    const highestPlan = planTypes.findLast((planType) => inputPlanTypes.includes(planType))

    return highestPlan ?? null
}

// TODO: Decouple features from pageOrigin - BFR-1306
export const getRequiredPlanPerFeature = (feature: PageOrigin): CpqRatePlanType => {
    switch (feature) {
        case 'guidelines':
            return 'BEYOND'
        case 'budgets':
        case 'tag-review':
        case 'saml-sso':
        case 'add-people-integrations-promo':
        case 'company-integrations-people-management-promo':
        case 'people-management-app-details':
            return 'ADVANCED'
        case 'recurring-vendors':
        case 'reimbursements':
        case 'teams':
        case 'limits':
        case 'analytics':
        case 'spend-review':
        case 'sequential-review':
        case 'company-review':
        case 'invoices':
            return 'ESSENTIAL'
        case 'activate-invoices':
        case 'vendor-cards':
        case 'suggested-vendor-cards':
        case 'virtual-cards':
        default:
            return 'STARTER'
    }
}

// TODO: Decouple features from pageOrigin - BFR-1306
// NOTE: This is copy/pasted from the PageOrigin type defined in the paywall package as a quick fix to avoid importing
// from the paywall package and causing circular dependencies. This should be refactored into a list of feature names,
// rather than page origins.
export type PageOrigin =
    | 'guidelines'
    | 'pocket'
    | 'reimbursements'
    | 'recurring-vendors'
    | 'analytics'
    | 'budgets'
    | 'teams'
    | 'limits'
    | 'billing'
    | 'settings-accounting'
    | 'company-review'
    | 'tag-review'
    | 'vendor-cards'
    | 'suggested-vendor-cards'
    | 'popular-vendor-cards'
    | 'offboarding'
    | 'promo'
    | 'add-people-integrations-promo'
    | 'company-integrations-people-management-promo'
    | 'people-management-app-details'
    | 'trial'
    | 'end-trial'
    | 'get-started'
    | 'multi-entity'
    | 'saml-sso'
    | 'sequential-review'
    | 'card-defaults'
    | 'expenses'
    | 'wallet'
    | 'member-details'
    | 'activate-invoices'
    | 'overdraft'
    | 'cash-management'
    | 'reimbursement-only-users-add-on'
    | 'virtual-cards'
    | 'spend-review'
    | 'invoices'
