import styled, {css} from 'styled-components'

import {Button, Inline, Stack, Text, tokens} from '@pleo-io/telescope'

type TMobileStyle = {
    isMobile?: boolean
}

export const IntegrationWrapper = styled(Stack)<TMobileStyle>`
    padding: ${tokens.spacing24} 0;
    border-bottom: ${tokens.borderPrimary};

    & > ${Inline} {
        ${({isMobile}) =>
            isMobile &&
            css`
                margin-left: 0;
                width: 100%;
            `}
    }
`

export const IntegrationInfo = styled(Inline)`
    width: 100%;
`

export const Logo = styled(Inline)`
    box-sizing: border-box;
    margin-bottom: auto;
    margin-right: ${tokens.spacing24};
    width: ${tokens.spacing56};
    height: 100%;

    & > :first-child {
        width: ${tokens.spacing56};
    }
`

export const Header = styled(Inline)`
    text-transform: capitalize;
`

export const Details = styled(Stack)`
    margin-right: ${tokens.spacing60};
`

export const DetailsText = styled(Text).attrs({variant: 'medium-default', as: 'p'})`
    width: 100%;
    max-width: 65ch;
`

export const DescriptionExtended = styled(Text).attrs({variant: 'medium-default', as: 'p'})`
    margin-top: ${tokens.spacing6};
    line-height: ${tokens.lineHeight3};
`

export const IntegrationToggle = styled(Button)<TMobileStyle>`
    margin: ${({isMobile}) => (isMobile ? `${tokens.spacing24} 0 0` : 'auto 0 auto auto')};
    width: ${({isMobile}) => (isMobile ? '100%' : 'unset')};
`

export const LearnMore = styled(Button)`
    margin: 0;
`

export const StatusTag = styled.div<{enabled?: boolean}>`
    padding: ${tokens.spacing2} ${tokens.spacing6};
    margin-left: ${tokens.spacing8};
    font-size: ${tokens.spacing10};
    font-weight: ${tokens.fontWeightBold};
    line-height: ${tokens.lineHeight2};
    text-transform: uppercase;
    letter-spacing: 0.4px;
    white-space: nowrap;
    border-radius: ${tokens.arc8};
    color: ${tokens.colorContentStatic};
    background-color: ${({enabled = false}) =>
        enabled ? tokens.colorBackgroundPresentationalGreen : tokens.colorBackgroundNeutralQuiet};
`

export const ToggleDisabledText = styled(Text)<TMobileStyle>`
    align-self: center;
    min-width: 8rem;
    margin: ${({isMobile}) => (isMobile ? `${tokens.spacing24} 0 0` : 'auto 0 auto auto')};
    width: ${({isMobile}) => (isMobile ? '100%' : 'unset')};
    text-align: ${({isMobile}) => (isMobile ? 'center' : 'unset')};
`
