import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/shared--user'

import {
    CashManagementPageErrorBoundary,
    CashManagementTabErrorBoundary,
} from './components/cash-management-error-boundary'
import CashManagementScreenWrapper from './screens/cash-managment-screen-wrapper'

const CashManagementScreen = React.lazy(
    async () => import('./screens/cash-management-screen/cash-management-screen'),
)

const AlertTab = React.lazy(async () => import('./components/tabs/alert/alert-tab'))
const AutoTopUpTab = React.lazy(async () => import('./components/tabs/auto-top-up/auto-top-up-tab'))
const BalanceHistoryTab = React.lazy(
    async () => import('./components/tabs/balance-history/balance-history-tab'),
)

const ChargebackPage = React.lazy(async () => import('./screens/chargeback/chargeback'))

const DirectDebitTab = React.lazy(
    async () => import('./components/tabs/direct-debit/direct-debit-tab'),
)
const DirectDebitCreation = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/direct-debit/direct-debit-creation/direct-debit-creation'
        ),
)

const WalletTab = React.lazy(async () => import('./components/tabs/wallet/wallet-tab'))
const OverdraftTab = React.lazy(async () => import('./components/tabs/overdraft/overdraft-tab'))
const OverdraftStatements = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/overdraft-statements/overdraft-statements'
        ),
)

const OverdraftManagement = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/overdraft-management/overdraft-management'
        ),
)
const ChangeOverdraftLimitPage = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/change-overdraft-limit/change-overdraft-limit'
        ),
)
const EditOverdraftPage = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/edit-overdraft/edit-overdraft'
        ),
)

const HigherLimitApplication = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/higher-limit-application/higher-limit-application'
        ),
)

const HigherLimitApplicationSuccessPage = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/higher-limit-application/higher-limit-application-success-page'
        ),
)

const HigherLimitApplicationRejectionInfo = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/higher-limit-application/higher-limit-rejection-info'
        ),
)

const OverdraftInfoRequiredSuccess = React.lazy(
    async () =>
        import('@product-web/feature--funds-management/credit/overdraft-info-required-success'),
)

const OverdraftApplicationApprovalInfo = React.lazy(
    async () =>
        import('@product-web/feature--funds-management/credit/overdraft-application-approval-info'),
)

const HigherLimitInfoRequired = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/higher-limit-application/higher-limit-info-required'
        ),
)

const OverdraftEligibilityApplication = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/overdraft-eligibility-application/overdraft-eligibility-application'
        ),
)

const OverdraftEligibilityApplicationSuccessPage = React.lazy(
    async () =>
        import(
            '@product-web/feature--funds-management/credit/components/overdraft-eligibility-application/eligibility-application-success-page'
        ),
)

const OverdraftEligibilityApplicationInfoRequired = React.lazy(
    async () =>
        import(
            '@product-web/feature--funds-management/credit/overdraft-eligibility-application-info-required.tsx'
        ),
)

const OverdraftEligibilityApplicationRejectionInfo = React.lazy(
    async () =>
        import(
            '@product-web/feature--funds-management/credit/overdraft-application-rejection-info'
        ),
)

const WithdrawalScreen = React.lazy(
    async () => import('./screens/withdrawal-screen/withdrawal-screen'),
)

const PreApprovedOverdraftPage = React.lazy(
    async () => import('./screens/pre-approved-overdraft/pre-approved-overdraft'),
)

const UnloadPage = React.lazy(async () => import('./screens/unload/unload'))

const SubWalletCreationPage = React.lazy(
    async () => import('./screens/sub-wallet-creating-screen/sub-wallet-creation'),
)

const AutoTopUpActivationPage = React.lazy(async () => import('./screens/auto-top-up-activation'))

// todo: the rest of the Cash Management route will be nested in the CashManagementScreen
//  https://linear.app/pleo/issue/WALLE-5900
export const routes = (
    <Route element={<CashManagementPageErrorBoundary />}>
        <Route
            path="chargeback"
            element={<ChargebackPage />}
            handle={{
                auth: true,
                allowedRoles: roleSets.company,
            }}
        />
        <Route
            handle={{
                auth: true,
                minCompanyStatus: 'fdd',
                allowedRoles: roleSets.ownerAndBookkeeper,
            }}
            element={<CashManagementScreenWrapper />}
        >
            <Route element={<CashManagementScreen />}>
                <Route element={<CashManagementTabErrorBoundary />}>
                    <Route index element={<WalletTab />} />
                    <Route path="alerts" element={<AlertTab />} />
                    <Route path="auto-top-up" element={<AutoTopUpTab />} />
                    <Route path="balance-history" element={<BalanceHistoryTab />} />
                    <Route path="direct-debit" element={<DirectDebitTab />} />
                    <Route path="overdraft" element={<OverdraftTab />} />
                </Route>
            </Route>
            <Route path="auto-top-up-activation" element={<AutoTopUpActivationPage />} />
            <Route path="withdrawal" element={<WithdrawalScreen />} />
            <Route path="unload" element={<UnloadPage />} />

            <Route path="direct-debit/create" element={<DirectDebitCreation />} />
            <Route path="direct-debit/create/:product?" element={<DirectDebitCreation />} />

            <Route path="create-sub-wallet" element={<SubWalletCreationPage />} />

            <Route path="overdraft/statements" element={<OverdraftStatements />} />
            <Route path="overdraft/manage" element={<OverdraftManagement />} />
            <Route path="overdraft/manage/change-limit" element={<ChangeOverdraftLimitPage />} />
            <Route path="overdraft/activate" element={<EditOverdraftPage operation="activate" />} />
            <Route path="overdraft/renew" element={<EditOverdraftPage operation="renew" />} />
            <Route path="overdraft/higher-limit-application" element={<HigherLimitApplication />} />
            <Route
                path="overdraft/higher-limit-application/success"
                element={<HigherLimitApplicationSuccessPage />}
            />
            <Route
                path="overdraft/higher-limit-application/rejection-info"
                element={<HigherLimitApplicationRejectionInfo />}
            />
            <Route
                path="overdraft/higher-limit-application/approval-info"
                element={<OverdraftApplicationApprovalInfo />}
            />
            <Route
                path="overdraft/higher-limit-application/info-required"
                element={<HigherLimitInfoRequired />}
            />
            <Route
                path="overdraft/higher-limit-info-required/success"
                element={<OverdraftInfoRequiredSuccess />}
            />
            <Route
                path="overdraft/eligibility-application"
                element={<OverdraftEligibilityApplication />}
            />
            <Route
                path="overdraft/eligibility-application/success"
                element={<OverdraftEligibilityApplicationSuccessPage />}
            />
            <Route
                path="overdraft/eligibility-application/info-required"
                element={<OverdraftEligibilityApplicationInfoRequired />}
            />

            <Route
                path="overdraft/eligibility-application/info-required/success"
                element={<OverdraftInfoRequiredSuccess />}
            />

            <Route
                path="overdraft-application-rejection-info"
                element={<OverdraftEligibilityApplicationRejectionInfo />}
            />

            <Route
                path="overdraft-application-approval-info"
                element={<OverdraftApplicationApprovalInfo />}
            />
            <Route path="overdraft/pre-approved" element={<PreApprovedOverdraftPage />} />
        </Route>
    </Route>
)
