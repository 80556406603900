import type {BookkeeperPermission} from '@pleo-io/deimos'

import {request} from '@product-web/shared--api'
import {getSessionData} from '@product-web/shared--auth--session/operations'
import config from '@product-web/shared--config'

import {postDeimos} from './helpers'

const baseUrl = config.endpoints.api

export const useBookkeeperPermissions = (
    bookkeeperUserId?: string | null,
    companyId?: string | null | undefined,
) => {
    const update = async (permission: BookkeeperPermission) => {
        if (!bookkeeperUserId || !companyId) {
            return
        }
        await updateBookkeeperPermission(bookkeeperUserId, permission, {companyId})
    }

    const destroy = async (permission: BookkeeperPermission) => {
        if (!bookkeeperUserId || !companyId) {
            return
        }
        await deleteBookkeeperPermission(bookkeeperUserId, permission, {companyId})
    }

    return {mutations: {update, destroy}}
}

interface BookkeeperPermissionsRequestBody {
    companyId: string
}

async function updateBookkeeperPermission(
    bookkeeperUserId: string,
    permission: BookkeeperPermission,
    payload: BookkeeperPermissionsRequestBody,
) {
    //Allow cross-entities mutations
    // related to https://linear.app/pleo/issue/ME-7260
    const {
        data: {accessToken: companyAccessToken},
    } = await getSessionData({companyId: payload.companyId})

    return request(`${baseUrl}/rest/v1/bookkeeper/${bookkeeperUserId}/permission/${permission}`, {
        auth: 'user',
        method: 'PUT',
        body: payload,
        ...(companyAccessToken && {bearer: companyAccessToken}),
    })
}

async function deleteBookkeeperPermission(
    bookkeeperUserId: string,
    permission: BookkeeperPermission,
    payload: BookkeeperPermissionsRequestBody,
) {
    //Allow cross-entities mutations
    // related to https://linear.app/pleo/issue/ME-7260
    const {
        data: {accessToken: companyAccessToken},
    } = await getSessionData({companyId: payload.companyId})
    return request(`${baseUrl}/rest/v1/bookkeeper/${bookkeeperUserId}/permission/${permission}`, {
        auth: 'user',
        method: 'DELETE',
        body: payload,
        ...(companyAccessToken && {bearer: companyAccessToken}),
    })
}

export interface CreateBookkeeperPayload {
    email: string
    firstName?: string
    resourceName?: string
    resourceId: string
    metadata?: object
    type: 'bookkeeper-basic' | 'bookkeeper-extended'
    parentResource?: string
    parentResourceId?: string
}

interface CreateBookkeeperResponse {
    success: boolean
    message: string
}

interface AcceptBookkeeperInvitePayload {
    firstName?: string
    lastName?: string
}

interface AcceptBookkeeperInviteResponse {
    userId: string
    verifyToken: string
}

export async function create(body: CreateBookkeeperPayload): Promise<CreateBookkeeperResponse> {
    return postDeimos('/rest/v1/bookkeepers/invites', body)
}

export async function acceptInvite(
    token: string,
    body?: AcceptBookkeeperInvitePayload,
): Promise<AcceptBookkeeperInviteResponse> {
    return postDeimos(`/rest/v1/bookkeepers/invites/${token}/accept`, body)
}
