import {Trans} from '@lingui/macro'
import type {ReactElement, ReactNode} from 'react'
import {useEffect, useState} from 'react'

import type {CalloutVariants} from '@pleo-io/telescope'
import {tokens} from '@pleo-io/telescope'
import {Callout, Inline, Link, Skeleton, Stack, Text} from '@pleo-io/telescope'

import {ReactComponent as LogoSvg} from '@product-web/shared--images/integrations/travelperk.svg'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {
    DescriptionExtended,
    Details,
    DetailsText,
    Header,
    IntegrationInfo,
    IntegrationToggle,
    IntegrationWrapper,
    LearnMore,
    Logo,
    StatusTag,
    ToggleDisabledText,
} from './integration.styles'

export interface IIntegrationProps {
    link?: string
    enabled: boolean
    fetching?: boolean
    linkLabel?: string
    integration: string
    logo?: ReactElement
    children?: ReactNode
    description?: string
    statusTagText?: string
    canBeToggled?: boolean
    onLinkClick?: () => void
    expandedDescription?: string
    toggleDisabledLabel?: string
    onEnable?: () => Promise<void> | void
    onDisable?: () => Promise<void> | void
    callout?: {variant: CalloutVariants; content: string}
}

export const Integration = ({
    children = null,
    fetching,
    integration,
    enabled,
    logo,
    statusTagText,
    description,
    expandedDescription,
    link,
    linkLabel,
    onLinkClick = () => {},
    onEnable,
    onDisable,
    canBeToggled = true,
    toggleDisabledLabel,
    callout,
}: IIntegrationProps) => {
    const [isFetching, setFetching] = useState(!!fetching)
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobileLrgUp})`)
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)

    useEffect(() => {
        setFetching(!!fetching)
    }, [fetching])

    const enable = async () => {
        if (!onEnable) {
            return
        }

        setFetching(true)
        await onEnable()
        setFetching(false)
    }

    const disable = async () => {
        if (!onDisable) {
            return
        }

        setFetching(true)
        await onDisable()
        setFetching(false)
    }

    const expand = () => {
        onLinkClick()
        setIsDescriptionExpanded(true)
    }

    return (
        <IntegrationWrapper
            isMobile={isMobile}
            data-testid={`integration-${integration.toLowerCase()}`}
        >
            <IntegrationInfo>
                <Logo alignX="center">{logo}</Logo>
                <Details space={4}>
                    <Header alignY="center">
                        <Text variant="2xlarge-accent" as="h3">
                            {integration.toLowerCase()}
                        </Text>
                        {statusTagText && <StatusTag enabled={enabled}>{statusTagText}</StatusTag>}
                    </Header>
                    {description ? (
                        <Stack space={4}>
                            <DetailsText color="colorContentInteractive">{description}</DetailsText>

                            {expandedDescription && // has extra text to show
                                (isDescriptionExpanded ? ( // has been expanded by the user
                                    <Stack space={10}>
                                        <DescriptionExtended>
                                            {expandedDescription}
                                        </DescriptionExtended>
                                        {link && ( // has a link to show below the expanded description
                                            <Link
                                                href={link}
                                                target="_blank"
                                                onClick={onLinkClick}
                                                rel="noopener noreferrer"
                                                data-testid="integration-link"
                                            >
                                                {linkLabel || <Trans>Learn more</Trans>}
                                            </Link>
                                        )}
                                    </Stack>
                                ) : (
                                    // hasn't been expanded by user
                                    <Inline>
                                        <LearnMore
                                            variant="tertiary"
                                            data-testid="learn-more"
                                            onClick={expand}
                                        >
                                            <Trans>Learn more</Trans>
                                        </LearnMore>
                                    </Inline>
                                ))}
                        </Stack>
                    ) : callout ? (
                        <Callout variant={callout.variant} css={{marginTop: tokens.spacing8}}>
                            <Stack>
                                <Callout.Content>{callout.content}</Callout.Content>
                            </Stack>
                        </Callout>
                    ) : null}
                </Details>
                {!isMobile &&
                    (canBeToggled ? (
                        <IntegrationToggle
                            loading={isFetching}
                            disabled={isFetching}
                            data-testid="action-button"
                            onClick={enabled ? disable : enable}
                            variant={enabled ? 'secondary' : 'primary'}
                        >
                            {enabled ? <Trans>Disable</Trans> : <Trans>Enable</Trans>}
                        </IntegrationToggle>
                    ) : (
                        <ToggleDisabledText
                            variant="medium-default"
                            color="colorContentStaticQuiet"
                        >
                            {toggleDisabledLabel}
                        </ToggleDisabledText>
                    ))}
            </IntegrationInfo>
            {children}
            {isMobile &&
                (canBeToggled ? (
                    <IntegrationToggle
                        isMobile
                        loading={isFetching}
                        disabled={isFetching}
                        data-testid="action-button"
                        onClick={enabled ? disable : enable}
                        variant={enabled ? 'secondary' : 'primary'}
                    >
                        {enabled ? <Trans>Disable</Trans> : <Trans>Enable</Trans>}
                    </IntegrationToggle>
                ) : (
                    <ToggleDisabledText
                        isMobile
                        variant="medium-default"
                        color="colorContentStaticQuiet"
                    >
                        {toggleDisabledLabel}
                    </ToggleDisabledText>
                ))}
        </IntegrationWrapper>
    )
}

/* eslint-disable string-to-lingui/missing-lingui-transformation */
const IntegrationSkeleton = () => (
    <IntegrationWrapper>
        <IntegrationInfo>
            <Skeleton borderRadius={99999}>
                <Logo>
                    <LogoSvg width="100%" height="100%" />
                </Logo>
            </Skeleton>
            <Details>
                <Header>
                    <Text variant="2xlarge-accent">
                        <Skeleton borderRadius={4}>Integration loading</Skeleton>
                    </Text>
                </Header>
                <DetailsText>
                    <Skeleton>Integration loading</Skeleton>
                </DetailsText>
            </Details>
            <Skeleton borderRadius={99999}>
                <IntegrationToggle variant="primary" />
            </Skeleton>
        </IntegrationInfo>
    </IntegrationWrapper>
)
Integration.Skeleton = IntegrationSkeleton
/* eslint-enable string-to-lingui/missing-lingui-transformation */
