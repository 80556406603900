import {createGlobalStyle} from 'styled-components'

import {__classNameColorSchemeDark, tokens} from '@pleo-io/telescope'

export type CustomColorSchemeToken = {light: string | number; dark: string | number}

export const customColorSchemeTokens = {
    colorBackgroundSwitchTrackOn: 'var(--colorBackgroundSwitchTrackOn)',
    colorBackgroundSwitchTrackOff: 'var(--colorBackgroundSwitchTrackOff)',
    colorBackgroundSwitchTrackDisabled: 'var(--colorBackgroundSwitchTrackDisabled)',
    colorBackgroundSwitchThumbDisabled: 'var(--colorBackgroundSwitchThumbDisabled)',
    colorContentSwitchThumbDisabled: 'var(--colorContentSwitchThumbDisabled)',
    colorBackgroundEntitySwitcher: 'var(--colorBackgroundEntitySwitcher)',
    colorBackgroundEntitySwitcherHover: 'var(--colorBackgroundEntitySwitcherHover)',
    colorBackgroundEntitySwitcherActive: 'var(--colorBackgroundEntitySwitcherActive)',
    colorBackgroundEntitySwitcherPanel: 'var(--colorBackgroundEntitySwitcherPanel)',
    colorContentPresentationalPink: 'var(--colorContentPresentationalPink)',
    colorExportStatusCellIconQueued: 'var(--colorExportStatusCellIconQueued)',
    colorBackgroundExpensesDot: 'var(--colorBackgroundExpensesDot)',
    colorBackgroundIndicator: 'var(--colorBackgroundIndicator)',
    colorBackgroundFileDropHover: 'var(--colorBackgroundFileDropHover)',
    colorBackgroundConfigurationSettingCompleted:
        'var(--colorBackgroundConfigurationSettingCompleted)',
    colorBorderEntityCardHover: 'var(--colorBorderEntityCardHover)',
    colorBackgroundSelfExpansionEntityCardHover:
        'var(--colorBackgroundSelfExpansionEntityCardHover)',
    // Invoice StatusIcon
    colorBackgroundInvoiceStatusIconPositive: 'var(--colorBackgroundInvoiceStatusIconPositive)',
    colorBackgroundInvoiceStatusIconPositiveHover:
        'var(--colorBackgroundInvoiceStatusIconPositiveHover)',
    colorBackgroundInvoiceStatusIconWarning: 'var(--colorBackgroundInvoiceStatusIconWarning)',
    colorBackgroundInvoiceStatusIconWarningHover:
        'var(--colorBackgroundInvoiceStatusIconWarningHover)',
    colorBackgroundInvoiceStatusIconNegative: 'var(--colorBackgroundInvoiceStatusIconNegative)',
    colorBackgroundInvoiceStatusIconNegativeHover:
        'var(--colorBackgroundInvoiceStatusIconNegativeHover)',
    colorBackgroundInvoiceStatusIconNeutral: 'var(--colorBackgroundInvoiceStatusIconNeutral)',
    colorBackgroundInvoiceStatusIconNeutralHover:
        'var(--colorBackgroundInvoiceStatusIconNeutralHover)',
    colorChartBorder: 'var(--colorChartBorder)',
    colorChartElementBackground: 'var(--colorChartElementBackground)',
    colorChartElementBackgroundHover: 'var(--colorChartElementBackgroundHover)',
    colorBackgroundPasscodeInput: 'var(--colorBackgroundPasscodeInput)',
    colorBackgroundDedicatedMiniCard: 'var(--colorBackgroundDedicatedMiniCard)',
    colorContentDedicatedMiniCard: 'var(--colorContentDedicatedMiniCard)',
    colorBorderConfigurationSettingsHeader: 'var(--colorBorderConfigurationSettingsHeader)',
    colorContentNewTagModalTagWhitePink: 'var(--colorContentNewTagModalTagWhitePink)',
    colorBorderNewTagModalTagWhitePink: 'var(--colorBorderNewTagModalTagWhitePink)',
    colorContentNewTagModalTagTransparent: 'var(--colorContentNewTagModalTagTransparent)',
    colorContentNewTagModalTagLightGrey: 'var(--colorContentNewTagModalTagLightGrey)',
    colorContentNewTagModalTagWhiteGrey: 'var(--colorContentNewTagModalTagWhiteGrey)',
    colorContentSpreadsheetActionsPopupHover: 'var(--colorContentSpreadsheetActionsPopupHover)',
    colorBackgroundNewTagModalTagPink: 'var(--colorBackgroundNewTagModalTagPink)',
    colorBackgroundNewTagModalTagGreen: 'var(--colorBackgroundNewTagModalTagGreen)',
    colorContentNewTagModalTagWhiteBlue: 'var(--colorContentNewTagModalTagWhiteBlue)',
    colorBorderNewTagModalTagWhiteBlue: 'var(--colorBorderNewTagModalTagWhiteBlue)',
    colorBackgroundNewTagModalTagWhiteBlue: 'var(--colorBackgroundNewTagModalTagWhiteBlue)',
    colorBackgroundTagSpreadsheetCellRange: 'var(--colorBackgroundTagSpreadsheetCellRange)',
    colorContentCellGrey: 'var(--colorContentCellGrey)',
    colorBackgroundPlaceholderFileInput: 'var(--colorBackgroundPlaceholderFileInput)',
    colorBorderExportTableInternalGradientStart:
        'var(--colorBorderExportTableInternalGradientStart)',
    colorBorderExportTableInternalGradientEnd: 'var(--colorBorderExportTableInternalGradientEnd)',
    colorBorderTemporaryLimit: 'var(--colorBorderTemporaryLimit)',
    colorBackgroundDisabledCard: 'var(--colorBackgroundDisabledCard)',
    colorBorderPleoCardDisabled: 'var(--colorBorderPleoCardDisabled)',
    colorBackgroundPleoCardDisabled: 'var(--colorBackgroundPleoCardDisabled)',
    colorBorderPleoCardVirtual: 'var(--colorBorderPleoCardVirtual)',
    colorContentCashIcon: 'var(--colorContentCashIcon)',
    colorBackgroundDedicatedCardBackElements: 'var(--colorBackgroundDedicatedCardBackElements)',
    colorBackgroundVirtualCardBackElements: 'var(--colorBackgroundVirtualCardBackElements)',
    colorBackgroundReimbursementsOnboardingBlue:
        'var(--colorBackgroundReimbursementsOnboardingBlue)',
    colorBackgroundReimbursementsOnboardingYellow:
        'var(--colorBackgroundReimbursementsOnboardingYellow)',
    colorBackgroundReimbursementsOnboardingStepIndicator:
        'var(--colorBackgroundReimbursementsOnboardingStepIndicator)',
    colorBackgroundDirectReimbursementsBlue: 'var(--colorBackgroundDirectReimbursementsBlue)',
    colorBackgroundSegmentedControlSelected: 'var(--colorBackgroundSegmentedControlSelected)',
} as const

type Data = Record<keyof typeof customColorSchemeTokens, CustomColorSchemeToken>

const customColorSchemeTokensData: Data = {
    colorBackgroundSwitchTrackOn: {light: tokens.green800, dark: tokens.green600},
    colorBackgroundSwitchTrackOff: {light: tokens.shade600, dark: tokens.shade300},
    colorBackgroundSwitchTrackDisabled: {light: tokens.shade300, dark: tokens.shade700},
    colorContentSwitchThumbDisabled: {light: tokens.shade000, dark: tokens.shade900},
    colorBackgroundSwitchThumbDisabled: {light: tokens.shade500, dark: tokens.shade600},
    colorBackgroundEntitySwitcher: {light: '#e4e4e4', dark: '#3c3c3c'},
    colorBackgroundEntitySwitcherHover: {light: tokens.shade000, dark: tokens.shade900},
    colorBackgroundEntitySwitcherActive: {light: tokens.shade200, dark: tokens.shade800},
    colorBackgroundEntitySwitcherPanel: {light: tokens.shade200, dark: tokens.shade800},
    colorContentPresentationalPink: {light: tokens.pink700, dark: tokens.pink600},
    colorExportStatusCellIconQueued: {light: tokens.yellow800, dark: tokens.yellow600},
    colorBackgroundExpensesDot: {light: tokens.pink500, dark: tokens.pink600},
    colorBackgroundIndicator: {light: tokens.shade300, dark: tokens.shade700},
    colorBackgroundFileDropHover: {light: tokens.pink400, dark: tokens.pink800},
    colorBackgroundConfigurationSettingCompleted: {light: tokens.purple400, dark: tokens.purple800},
    colorBorderEntityCardHover: {light: tokens.shade500, dark: tokens.shade500},
    colorBackgroundSelfExpansionEntityCardHover: {light: tokens.shade000, dark: tokens.shade900},
    // Invoice StatusIcon. Details on why these were made custom in Slack:
    // https://getpleo.slack.com/archives/CPCFSJK1R/p1700138814903639?thread_ts=1700062397.254629&cid=CPCFSJK1R
    colorBackgroundInvoiceStatusIconPositive: {light: tokens.green400, dark: tokens.green800},
    colorBackgroundInvoiceStatusIconPositiveHover: {light: tokens.green500, dark: tokens.green700},
    colorBackgroundInvoiceStatusIconWarning: {light: tokens.yellow500, dark: tokens.yellow900},
    colorBackgroundInvoiceStatusIconWarningHover: {light: tokens.yellow600, dark: tokens.yellow700},
    colorBackgroundInvoiceStatusIconNegative: {light: tokens.red400, dark: tokens.red800},
    colorBackgroundInvoiceStatusIconNegativeHover: {light: tokens.red500, dark: tokens.red700},
    colorBackgroundInvoiceStatusIconNeutral: {light: tokens.shade300, dark: tokens.shade700},
    colorBackgroundInvoiceStatusIconNeutralHover: {light: tokens.shade400, dark: tokens.shade600},
    colorChartBorder: {light: tokens.pink500, dark: tokens.pink700},
    colorChartElementBackground: {light: tokens.pink300, dark: tokens.pink500},
    colorChartElementBackgroundHover: {light: tokens.pink500, dark: tokens.pink700},
    colorBackgroundPasscodeInput: {light: tokens.shade300, dark: tokens.shade600},
    colorBackgroundDedicatedMiniCard: {light: tokens.shade700, dark: tokens.shade300},
    colorContentDedicatedMiniCard: {light: tokens.shade300, dark: tokens.shade700},
    colorBorderConfigurationSettingsHeader: {light: tokens.shade900, dark: tokens.shade000},
    colorContentNewTagModalTagWhitePink: {light: tokens.pink700, dark: tokens.pink300},
    colorBorderNewTagModalTagWhitePink: {light: tokens.pink700, dark: tokens.pink300},
    colorContentNewTagModalTagTransparent: {light: tokens.shade300, dark: tokens.shade700},
    colorContentNewTagModalTagLightGrey: {light: tokens.shade300, dark: tokens.shade700},
    colorContentNewTagModalTagWhiteGrey: {light: tokens.shade300, dark: tokens.shade700},
    colorContentSpreadsheetActionsPopupHover: {light: tokens.pink600, dark: tokens.pink700},
    colorBackgroundNewTagModalTagPink: {light: tokens.pink600, dark: tokens.pink700},
    colorBackgroundNewTagModalTagGreen: {light: tokens.green600, dark: tokens.green700},
    colorContentNewTagModalTagWhiteBlue: {light: tokens.blue600, dark: tokens.blue700},
    colorBorderNewTagModalTagWhiteBlue: {light: tokens.blue600, dark: tokens.blue700},
    colorBackgroundNewTagModalTagWhiteBlue: {light: tokens.blue300, dark: tokens.blue700},
    colorBackgroundTagSpreadsheetCellRange: {light: tokens.blue300, dark: tokens.blue700},
    colorContentCellGrey: {light: tokens.shade300, dark: tokens.shade700},
    colorBackgroundPlaceholderFileInput: {light: tokens.shade300, dark: tokens.shade700},
    colorBorderExportTableInternalGradientStart: {
        light: tokens.shade900lighten10,
        dark: tokens.shade900lighten60,
    },
    colorBorderExportTableInternalGradientEnd: {
        light: tokens.shade900lighten05,
        dark: tokens.shade900lighten80,
    },
    colorBorderTemporaryLimit: {light: tokens.blue400, dark: tokens.blue600},
    colorBackgroundDisabledCard: {light: tokens.blue300, dark: tokens.blue400},
    colorBorderPleoCardDisabled: {light: tokens.blue400, dark: tokens.blue600},
    colorBackgroundPleoCardDisabled: {light: tokens.blue300, dark: tokens.blue400},
    colorBorderPleoCardVirtual: {light: tokens.yellow500, dark: tokens.yellow600},
    colorContentCashIcon: {light: tokens.purple700, dark: tokens.purple800},
    colorBackgroundDedicatedCardBackElements: {light: tokens.shade700, dark: tokens.shade800},
    colorBackgroundVirtualCardBackElements: {light: tokens.yellow300, dark: tokens.yellow400},
    colorBackgroundReimbursementsOnboardingBlue: {light: tokens.blue300, dark: tokens.blue400},
    colorBackgroundReimbursementsOnboardingYellow: {
        light: tokens.yellow300,
        dark: tokens.yellow400,
    },
    colorBackgroundReimbursementsOnboardingStepIndicator: {
        light: tokens.yellow500,
        dark: tokens.yellow600,
    },
    colorBackgroundDirectReimbursementsBlue: {light: tokens.blue300, dark: tokens.blue400},
    colorBackgroundSegmentedControlSelected: {
        light: 'rgba(0,0,0,0.05)',
        dark: 'rgba(255,255,255,0.2)',
    },
} as const

export const createCustomColorSchemeTokensGlobalStyle = (
    data: Record<string, CustomColorSchemeToken>,
) => createGlobalStyle`
:root {
    ${Object.keys(data).map((name) => `--${name}: ${data[name].light};`)}
    .${__classNameColorSchemeDark} {
        ${Object.keys(data).map((name) => `--${name}: ${data[name].dark};`)}
    }
}
`

export const CustomColorSchemeTokensGlobalStyle = createCustomColorSchemeTokensGlobalStyle(
    customColorSchemeTokensData,
)
