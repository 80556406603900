import {t, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import {useState} from 'react'
import styled from 'styled-components'

import {Button, Inline, Modal, px, Stack, Text, tokens} from '@pleo-io/telescope'
import {NewTab} from '@pleo-io/telescope-icons'

interface CardProps {
    description: ReactNode
    icon: ReactNode
    title: string
}

export const Card = ({description, icon, title}: CardProps) => (
    <CardWrapper>
        {icon}
        <Title>{title}</Title>
        <Text>{description}</Text>
    </CardWrapper>
)

interface DemoCardProps {
    demoUrl: string
    image: string
    title: string
}

export const DemoCard = ({demoUrl, image, title}: DemoCardProps) => {
    const [isModalOpen, setModalOpen] = useState(false)
    const openModal = () => setModalOpen(true)
    const closeModal = () => setModalOpen(false)

    return (
        <DemoWrapper>
            <Inline p={24} alignX="center" css={{width: '100%'}}>
                <Title>{title}</Title>
            </Inline>
            <DemoImageWrapper>
                <DemoButton
                    aria-label={t`Launch demo for ${title}`}
                    variant="secondary"
                    IconRight={NewTab}
                    onClick={openModal}
                >
                    <Trans>Launch demo</Trans>
                </DemoButton>
                <DemoImage src={image} alt={title} />
            </DemoImageWrapper>
            <StyledModal isOpen={isModalOpen}>
                <Modal.Close onClick={closeModal} />
                <Modal.Title>{title}</Modal.Title>
                <Iframe src={demoUrl} title={title} />
            </StyledModal>
        </DemoWrapper>
    )
}

const CardWrapper = styled(Stack).attrs({space: 12})`
    padding: ${tokens.spacing24} ${tokens.spacing16};
    border: ${tokens.borderPrimary};
    border-radius: ${tokens.arc8};
`
const Container = styled.div<{minChildWidth?: number}>`
    display: grid;
    ${({minChildWidth = 320}) =>
        `grid-template-columns: repeat(auto-fill, minmax(${minChildWidth}px, 1fr));`}
    gap: ${tokens.spacing24};
`
const DemoImage = styled.img`
    width: 100%;
    height: 100%;
    border-top: ${tokens.borderInteractiveQuiet};
    border-radius: 0 0 ${tokens.arc8} ${tokens.arc8};
    transition: ${tokens.smoothInOut};
`
const DemoWrapper = styled(Stack)`
    border: ${tokens.borderPrimary};
    border-radius: ${tokens.arc8};
`
const DemoImageWrapper = styled.div`
    position: relative;
    border-radius: 0 0 ${tokens.arc8} ${tokens.arc8};
    background-color: ${tokens.colorBackgroundInteractiveTransparent};
`
const DemoButton = styled(Button).attrs({variant: 'secondary'})`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    &:hover ~ ${DemoImage} {
        opacity: ${tokens.opacity80};
    }
`
const Iframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: ${tokens.borderInteractiveQuiet};
    border-radius: 0 0 ${tokens.arc8} ${tokens.arc8};
`
const StyledModal = styled(Modal)`
    padding-bottom: ${tokens.spacing8};
    width: calc(100vw - ${tokens.spacing10});
    height: calc(100vh - ${tokens.spacing10});
    background-color: ${tokens.colorBackgroundInteractive};

    /* The Navattic window has a 16:10 aspect ratio and scales to the size of the parent. Adding some sensible max limits */
    max-width: ${px(1600)};
    max-height: min(${px(1033)}, calc((10 / 16) * 100vw + ${tokens.spacing28}));
`
const Title = styled(Text).attrs({as: 'h5', variant: 'large-accent'})`
    font-weight: ${tokens.fontWeightSemibold};
`

interface CardGroupProps {
    children: ReactNode
    minChildWidth?: number
}
export const CardGroup = (props: CardGroupProps) => <Container {...props} />
