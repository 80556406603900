/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Beyond API
 * Service in charge of Billing orchestration and integration with Zuora
 * OpenAPI spec version: 19.13.0
 */
import {moonRequest as _moonRequest} from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('beyond')

export type ListWalletRefundsParams = {
    /**
     * The company identifier
     */
    company_id: string
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type ListWalletPaymentsParams = {
    /**
     * The company identifier
     */
    company_id: string
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type EstimateSubscriptionPriceParams = {
    /**
     * The quantity used for the estimation of the additional users rate plan if left null, the current quantity or commitment on the subscription will be used
     */
    additional_users_quantity?: number
    /**
     * The quantity used for the estimation of the additional pocket only users rate plan if left null, the current quantity or commitment on the subscription will be used
     */
    additional_pocket_only_users_quantity?: number
}

export type ListSubscriptionsParams = {
    /**
     * Id of the company for which to fetch subscriptions
     */
    companyId: string
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type ListProductsParams = {
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type GetPreSelectedPlatformPlanParams = {
    company_id?: string
}

export type GetPlanSelectionParams = {
    company_id?: string
}

export type GetCompanyInvoicesInfoParams = {
    /**
     * The company identifier
     */
    companyId: string
}

export type ListInvoicesParams = {
    /**
     * The company id
     */
    companyId?: string
    /**
     * Get invoices after this date, this date is exclusive
     */
    start_date?: string
    /**
     * Get invoices before this date, this date is exclusive
     */
    end_date?: string
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type ListCreditNotesParams = {
    /**
     * Company identifier
     */
    companyId?: string
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type UpdateSubscriptionParams = {
    companyId?: string
}

/**
 * Filter by the product type
 */
export type ListCpqProductsProductFiltersItem =
    (typeof ListCpqProductsProductFiltersItem)[keyof typeof ListCpqProductsProductFiltersItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListCpqProductsProductFiltersItem = {
    ADVANCED: 'ADVANCED',
    ESSENTIAL: 'ESSENTIAL',
    STARTER: 'STARTER',
} as const

export type ListCpqProductsCountryCode =
    (typeof ListCpqProductsCountryCode)[keyof typeof ListCpqProductsCountryCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListCpqProductsCountryCode = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export type ListCpqProductsParams = {
    countryCode?: ListCpqProductsCountryCode
    productFilters?: ListCpqProductsProductFiltersItem[]
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type EstimateSubscriptionPriceChangeParams = {
    companyId?: string
    /**
     * The quantity used for the estimation of the additional users rate plan if left null, the current quantity or commitment on the subscription will be used
     */
    additional_users_quantity?: number
    /**
     * The quantity used for the estimation of the additional pocket only users rate plan if left null, the current quantity or commitment on the subscription will be used
     */
    additional_pocket_only_users_quantity?: number
}

export type GetAvailableUpsellAddonsParams = {
    company_id?: string
}

export type ListChargeablesParams = {
    /**
     * The company identifier
     */
    companyId: string
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type CalculateCardOrderPriceParams = {
    /**
     * The company identifier
     */
    companyId?: string
}

export type UpdateCompanyVatIdParams = {
    /**
     * The company identifier
     */
    companyId: string
}

export type GetCompanyVatIdParams = {
    /**
     * The company identifier
     */
    companyId: string
}

export type UpdateCompanyBillingInformationParams = {
    /**
     * The company identifier
     */
    companyId: string
}

export interface WalletRefund {
    /** The unique identifier of the company */
    companyId: string
    /** The date and time when the refund was created */
    createdAt?: string
    /** The unique identifier of the credit note that this refund is associated with */
    creditNoteId: string
    /** The unique identifier of the expense that this refund is associated with */
    expenseId?: string
    /** The reason why the refund failed. If the refund was successful, this field will be null */
    failureReason?: string
    /** The unique identifier of the refund */
    id: string
    /** The internal payment id. This is the id that is used internally by Pleo to identify the payment. It can be also used as an idempotency key when creating a payment. Multiple wallet payments may have the same internal payment id. For example, if the previous payment failed, the same internal payment id will be used for the retry. */
    internalPaymentId: string
    /** The unique identifier of the invoice that this refund is associated with */
    invoiceId?: string
    /** The spend chain id. This is the id that is used to group payments that are part of the same spend chain */
    spendChainId?: string
    /** The status of the refund */
    status: string
}

export interface WalletRefundResponse {
    data: WalletRefund
}

export interface WalletRefundListResponse {
    data: WalletRefund[]
    pagination: CursorPageInfo
}

export interface WalletPayment {
    /** The reason was the charge was performed */
    chargeReason?: string
    /** The unique identifier of the company */
    companyId: string
    /** The date and time when the payment was created */
    createdAt?: string
    /** The unique identifier of the expense that this payment is associated with */
    expenseId?: string
    /** The reason why the payment failed. If the payment was successful, this field will be null */
    failureReason?: string
    /** The unique identifier of the payment */
    id: string
    /** The internal payment id. This is the id that is used internally by Pleo to identify the payment. It can be also used as an idempotency key when creating a payment. Multiple wallet payments may have the same internal payment id. For example, if the previous payment failed, the same internal payment id will be used for the retry. */
    internalPaymentId: string
    /** The unique identifier of the invoice that this payment is associated with */
    invoiceId: string
    /** The spend chain id. This is the id that is used to group payments that are part of the same spend chain */
    spendChainId?: string
    /** The status of the payment */
    status: string
}

export interface WalletPaymentResponse {
    data: WalletPayment
}

export interface WalletPaymentListResponse {
    data: WalletPayment[]
    pagination: CursorPageInfo
}

export interface UpdateCompanyVatRequest {
    /** The VAT ID of the company */
    vatId?: string
}

/**
 * The country code of the company's address
 */
export type UpdateCompanyBillingInformationRequestCountryCode =
    (typeof UpdateCompanyBillingInformationRequestCountryCode)[keyof typeof UpdateCompanyBillingInformationRequestCountryCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCompanyBillingInformationRequestCountryCode = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface UpdateCompanyBillingInformationRequest {
    /** The first line of the company's address */
    addressLine1: string
    /** The second line of the company's address */
    addressLine2?: string
    /** The name of the city/town where the company is located */
    city?: string
    /** The billing email of the company. Used to send invoices. */
    contactEmail: string
    /** The country code of the company's address */
    countryCode: UpdateCompanyBillingInformationRequestCountryCode
    /** The postal code of the company's address */
    postalCode: string
    /** The name of the region where the company is located */
    region?: string
    /** The name of the state where the company is located */
    state?: string
    /** The VAT ID of the company */
    vatId?: string
}

/**
 * The tax information of the company, such as VAT id.
 */
export interface TaxInfo {
    /** Company code used by the tax engine to identify the company. It not used if tax exempt is REVERSE_CHARGE. */
    taxEngineCompanyCode?: string
    /** VAT id of the company. It is used for VAT calculation and reporting. */
    vatId?: string
    /** The date when the VAT verification was last performed. It is null if the VAT verification was never performed. */
    vatLastVerificationDate?: string
    /** Result of the VAT verification performed by a VAT verification service. */
    vatVerificationStatus?: string
}

export type SubscriptionTermType = (typeof SubscriptionTermType)[keyof typeof SubscriptionTermType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionTermType = {
    EVERGREEN: 'EVERGREEN',
    TERMED: 'TERMED',
} as const

export type SubscriptionStatus = (typeof SubscriptionStatus)[keyof typeof SubscriptionStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionStatus = {
    ACTIVE: 'ACTIVE',
    CANCELLED: 'CANCELLED',
    TRIALING: 'TRIALING',
    PAST_DUE: 'PAST_DUE',
    UNPAID: 'UNPAID',
    UNDEFINED: 'UNDEFINED',
} as const

export interface SubscriptionResponse {
    data: Subscription
}

export type SubscriptionRatePlanType =
    (typeof SubscriptionRatePlanType)[keyof typeof SubscriptionRatePlanType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionRatePlanType = {
    OTHER: 'OTHER',
    MANAGING_PARTNER: 'MANAGING_PARTNER',
    PARTNERSHIP: 'PARTNERSHIP',
    PARTNER_CLIENT_FREE: 'PARTNER_CLIENT_FREE',
    STARTER: 'STARTER',
    ESSENTIAL: 'ESSENTIAL',
    ADVANCED: 'ADVANCED',
    BEYOND: 'BEYOND',
    INSURANCE: 'INSURANCE',
    ZERO_FX: 'ZERO_FX',
    FREE_ADMINISTRATOR: 'FREE_ADMINISTRATOR',
    EXTERNAL_BOOKKEEPER: 'EXTERNAL_BOOKKEEPER',
    CASHBACK: 'CASHBACK',
    MULTI_ENTITY_CASHBACK: 'MULTI_ENTITY_CASHBACK',
    INVOICES: 'INVOICES',
    ADDITIONAL_USERS: 'ADDITIONAL_USERS',
    CARD_ORDER: 'CARD_ORDER',
    ADDITIONAL_POCKET_ONLY_USERS: 'ADDITIONAL_POCKET_ONLY_USERS',
    BOOKKEEPERS: 'BOOKKEEPERS',
    REIMBURSEMENT: 'REIMBURSEMENT',
    PARTNER_DISCOUNT: 'PARTNER_DISCOUNT',
    TEMPORARY_CARDS: 'TEMPORARY_CARDS',
    SUB_WALLETS: 'SUB_WALLETS',
} as const

/**
 * The list of charges for this rate plan
 */
export interface SubscriptionRatePlanCharge {
    /** Specifies the number of users related to the rate plan charge in a specific moment.This number is not the current billed quantity for the charge and the values might differ. */
    allocatedUsers?: number
    /** Sets the bill cycle day (BCD) for the charge. The BCD determines which day of the month customer is billed. The BCD value in the account can override the BCD in this object. In case of a ONE_TIME charge, there is no billing cycle day.A valid BCD integer, 1 - 31 */
    billingCycleDay?: number
    billingPeriodLength: string
    /** Specifies the cashback percentage for a charge. It is a decimal number. */
    cashbackPercentage?: number
    /** The human readable identifier of a charge */
    chargeNumber: string
    /** Specifies the type of charge */
    chargeType: string
    /** The value that specifies customer's commitment for a rate plan charge. For example, a number of users committed by the customer. It means that a customer may add users in the future up to the specified amount. It can be bigger or equal the actual amount of the active users */
    commitment?: number
    discountDetails?: RatePlanChargeDiscountDetails
    /** The charge identifier */
    id: string
    /** The name of the charge */
    name: string
    pricing: Pricing
    /** Specifies quantity of a specific item. For example it may specify the number of additional users */
    quantity?: number
}

export type SubscriptionRatePlanCategory =
    (typeof SubscriptionRatePlanCategory)[keyof typeof SubscriptionRatePlanCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionRatePlanCategory = {
    PLATFORM: 'PLATFORM',
    ADDON: 'ADDON',
    ADDITIONAL_USERS: 'ADDITIONAL_USERS',
    OTHER: 'OTHER',
} as const

/**
 * The list of rate plans for addons. It contains all the items except platform such as additional users, Cashback, 0FX, Insurance
 */
export interface SubscriptionRatePlan {
    /** This can be one of SubscriptionRatePlanCategory values */
    category: string
    /** The list of charges for this rate plan */
    charges: SubscriptionRatePlanCharge[]
    /** The rate plan identifier */
    id: string
    /** The name of the rate plan */
    name: string
    /** The product id of the product from which this rate plan was copied */
    productId: string
    /** The name of the product from which this rate plan was copied */
    productName: string
    /** The id of the original product rate plan from which this rate plan was copied */
    productRatePlanId: string
    /** This can be one of SubscriptionRatePlanType values */
    type: string
}

/**
 * The estimated price change for each subscription item
 */
export interface SubscriptionItemPriceEstimation {
    amountIncludingTax: Amount
    amountWithoutTax: Amount
    /** The name of the subscription item */
    name: string
    tax: Amount
    /** This can be one of SubscriptionRatePlanType values */
    type: string
}

export interface SubscriptionPriceEstimation {
    /** This can be one of BillingPeriodType values */
    billingPeriodType: string
    /** The company identifier */
    companyId: string
    /** The estimated price change for each subscription item */
    items: SubscriptionItemPriceEstimation[]
    /** This can be one of SubscriptionRatePlanType values */
    ratePlanType: string
    tax: Amount
    totalIncludingTax: Amount
    totalWithoutTax: Amount
}

export interface SubscriptionPriceEstimationResponse {
    data: SubscriptionPriceEstimation
}

export interface SubscriptionChangeRequest {
    /** List of add-on rate plan ids to include in estimate, */
    addonRatePlanIds?: string[]
    /** The commitment sets the minimum number of billing periods for the subscription - billingPeriodType must be MONTH. For example, a commitment of 12 means that the subscription must be active for 12 billing periods. */
    billingPeriodCommitment?: number
    /** This can be one of CpqBillingPeriodType values */
    billingPeriodType: string
    /** This can be one of CpqRatePlanType values */
    ratePlanType: string
    /** The source that triggered the subscription update */
    subscriptionUpdateSource?: string
}

export interface Subscription {
    /** The list of rate plans for addons. It contains all the items except platform such as additional users, Cashback, 0FX, Insurance */
    addonRatePlans?: SubscriptionRatePlan[]
    /** This can be one of BillingPeriodType values */
    billingPeriodType: string
    /** The id of the company that owns the subscription */
    companyId: string
    currentBillingPeriod?: CurrentBillingPeriod
    /** The unique identifier of the subscription */
    id: string
    /** Returns true if the subscription is currently in trial */
    isInTrialPeriod: boolean
    /** Returns true if the subscription is using a legacy pricing generation */
    isLegacyPricing: boolean
    platformRatePlan: SubscriptionRatePlan
    /** This can be one of SubscriptionStatus values */
    status: string
    /** The date when the subscription was canceled. If the subscription is active the value is null. The date is in UTC */
    subscriptionCancelDate?: string
    /** The date when the subscription started. If the subscription was auto-renewed the value contains the very first subscription start date. The date is in UTC. The value is null if the subscription is in a TRIALING state. The value is in the future if the subscription is in a trial period.  */
    subscriptionStartDate?: string
    /** Date the subscription term ends. If the subscription is evergreen, this is null or is the cancellation date (if one has been set). */
    subscriptionTermEndDate?: string
    /** Date the subscription term begins. If this is a renewal subscription, this date is different from the subscription start date. */
    subscriptionTermStartDate?: string
    /** This can be one of SubscriptionTermType values */
    termType: string
    trialPeriod?: DateInterval
}

export interface SubscriptionListResponse {
    data: Subscription[]
    pagination: CursorPageInfo
}

/**
 * The discount information, specifies discount amount or percentage and for which rate plans the discount applies to
 */
export interface RatePlanChargeDiscountDetails {
    /** The rate plan ids this discount applies to */
    appliedRatePlans: string[]
    discountAmount?: Amount
    /** The duration in months for the discount */
    discountDurationInMonth?: number
    /** The discount percent applied to on or more rate plan charges */
    discountPercent?: number
}

export type ProductUsage = (typeof ProductUsage)[keyof typeof ProductUsage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductUsage = {
    CARDS: 'CARDS',
    REIMBURSEMENTS: 'REIMBURSEMENTS',
    SUPPLIERS: 'SUPPLIERS',
    SPENDING: 'SPENDING',
    SAVINGS: 'SAVINGS',
    BOOKKEEPING: 'BOOKKEEPING',
} as const

export interface ProductResponse {
    data: Product
}

export type ProductRatePlanCategory =
    (typeof ProductRatePlanCategory)[keyof typeof ProductRatePlanCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductRatePlanCategory = {
    PLATFORM: 'PLATFORM',
    ADDON: 'ADDON',
    ADDITIONAL_USERS: 'ADDITIONAL_USERS',
    OTHER: 'OTHER',
} as const

export interface ProductRatePlan {
    /** Whether the rate plan can be used for new purchases */
    active: boolean
    /** The date until which the rate plan can be used for new purchases */
    availabilityEndDate?: string
    /** The date from which the rate plan can be used for new purchases */
    availabilityStartDate: string
    /** This can be one of ProductRatePlanCategory values */
    category: string
    /** The list of charges for this rate plan */
    charges: ProductRatePlanCharge[]
    /** The short description of the rate plan */
    description: string
    /** The rate plan identifier */
    id: string
    /** The name of the rate plan */
    name: string
    /** The human readable identifier of a rate plan */
    ratePlanNumber: string
    /** The type identifier of the rate plan. The rate plan name can be any text. However, the type determines the associated behavior with the rate plan. For example, CASHBACK means that the company will receive cashback */
    type: string
}

export interface RatePlanListResponse {
    data: ProductRatePlan[]
}

/**
 * The list of tiers for tiered charge model. If the charge model is not tiered returns null
 */
export type ProductPricingTiers = {[key: string]: ChargeTier[]}

/**
 * The pricing for this charge. Contains information about pricing models, amounts, tiers, etc
 */
export interface ProductPricing {
    /** This can be one of ChargeModel values */
    chargeModel: string
    /** Defines price for non-tiered charge model. Returns null if the model is tiered */
    prices?: Amount[]
    /** The list of tiers for tiered charge model. If the charge model is not tiered returns null */
    tiers?: ProductPricingTiers
}

/**
 * The discounts information and amounts for this charge
 */
export interface ProductChargeDiscounts {
    /** The discount amounts applied to a rate plan charge for each currency */
    discountAmounts?: Amount[]
    /** The discount level applied to a rate plan charge */
    discountLevel?: string
    /** The discount percent applied to a rate plan charge */
    discountPercent?: number
}

/**
 * The list of charges for this rate plan
 */
export interface ProductRatePlanCharge {
    /** Determines the length of the billing period. For example if the length is 3 and the period is monthly, the billing period is 3 months. */
    billingPeriodLength: number
    /** The period for the charge. It can be monthly or yearly. The start day of the billing period is also called the bill cycle day (BCD) */
    billingPeriodType: string
    /** This can be one of ChargeType values */
    chargeType: string
    /** The description of the charge */
    description: string
    discounts: ProductChargeDiscounts
    /** The charge identifier */
    id: string
    /** The name of the charge */
    name: string
    pricing: ProductPricing
}

export interface Product {
    /** Whether the product is active or not */
    active: boolean
    /** The date until which the product can be used for new purchases */
    availabilityEndDate?: string
    /** The date from which the product can be used for new purchases */
    availabilityStartDate: string
    /** The description of the product */
    description: string
    /** The product identifier */
    id: string
    /** The name of the product */
    name: string
    ratePlans: ProductRatePlan[]
    /** The unique SKU for the product */
    sku: string
}

export interface ProductListResponse {
    data: Product[]
    pagination: CursorPageInfo
}

/**
 * The pricing for this charge. Contains information about pricing models, amounts, tiers, etc
 */
export interface Pricing {
    /** Specifies the charge model */
    chargeModel: string
    price?: Amount
    /** The list of tiers for tiered charge model. If the model is not charged returns null */
    tiers?: ChargeTier[]
}

export interface PreSelectedPlatformPlanResponse {
    /** The company id corresponding to the pre selected platform plan */
    companyId: string
    /** The date and time the pre selected platform plan was created */
    createdAt: string
    /** The id of user that created the pre selected platform plan */
    createdByUserId?: string
    /** The unique identifier of the pre selected platform plan */
    id: string
    /** The name of the plan that the company pre selected */
    planName: string
    /** The date and time the pre selected platform plan was last updated at */
    updatedAt: string
}

/**
 * The country code in ISO 3166 format
 */
export type PostalAddressCountry = (typeof PostalAddressCountry)[keyof typeof PostalAddressCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostalAddressCountry = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

/**
 * The billing address of the company
 */
export interface PostalAddress {
    /** The first line of the address */
    addressLine1?: string
    /** The second line of the address */
    addressLine2?: string
    /** The name of the city */
    city?: string
    /** The country code in ISO 3166 format */
    country?: PostalAddressCountry
    /** The postal code */
    postalCode?: string
    /** The name of the region */
    region?: string
    /** The name of the state */
    state?: string
}

export interface PlanSelectionResponse {
    /** The company id corresponding to the plan selection */
    companyId: string
    /** The size of the company */
    companySize: string
    /** The date and time when the plan selection was created */
    createdAt: string
    /** The unique identifier of the plan selection */
    id: string
    /** The selected product usages */
    productUsage: string[]
    /** The recommended plan type */
    recommendedPlanType: string
    /** The selected product sku */
    selectedProductSku: string
    /** The selected product type */
    selectedProductType: string
    /** The date and time when the plan selection was last updated */
    updatedAt: string
    /** The user id corresponding to the plan selection */
    userId: string
}

export type PlanSelectionCountryCode =
    (typeof PlanSelectionCountryCode)[keyof typeof PlanSelectionCountryCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanSelectionCountryCode = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface PlanSelection {
    /** This can be one of CpqBillingPeriodType values */
    billingPeriodType: string
    /** This can be one of CompanySize values */
    companySize: string
    countryCode: PlanSelectionCountryCode
    /** This can be one of CpqRatePlanType values */
    recommendedPlanType: string
    /** This can be one of CpqRatePlanType values */
    selectedPlanType: string
    usages: string[]
}

export interface PlanRecommendationResponse {
    /** description of the product */
    description: string
    /** name of product */
    name: string
    /** This can be one of CpqRatePlanType values */
    planType: string
    /** sku of the product */
    sku: string
}

/**
 * Country Code in alpha-2 format
 */
export type PlanRecommendationRequestCountryCode =
    (typeof PlanRecommendationRequestCountryCode)[keyof typeof PlanRecommendationRequestCountryCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanRecommendationRequestCountryCode = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface PlanRecommendationRequest {
    /** This can be one of CpqBillingPeriodType values */
    billingPeriodType: string
    /** This can be one of CompanySize values */
    companySize: string
    /** Country Code in alpha-2 format */
    countryCode: PlanRecommendationRequestCountryCode
    /** selected product usage */
    usages: string[]
}

export type PageOrder = (typeof PageOrder)[keyof typeof PageOrder]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PageOrder = {
    ASC: 'ASC',
    ASC_NULLS_FIRST: 'ASC_NULLS_FIRST',
    ASC_NULLS_LAST: 'ASC_NULLS_LAST',
    DESC: 'DESC',
    DESC_NULLS_FIRST: 'DESC_NULLS_FIRST',
    DESC_NULLS_LAST: 'DESC_NULLS_LAST',
} as const

export type OrganizationType = (typeof OrganizationType)[keyof typeof OrganizationType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationType = {
    SUB_COMPANY: 'SUB_COMPANY',
    FRANCHISE: 'FRANCHISE',
    STANDALONE: 'STANDALONE',
} as const

export interface InvoicesInfo {
    /** The company identifier */
    companyId: string
    /** The date of the first invoice */
    firstInvoiceDate?: string
    /** The date of the first paid invoice */
    firstPaidInvoiceDate?: string
    /** The amount of the next invoice */
    nextInvoiceAmount?: number
    /** The date of the next invoice */
    nextInvoiceDate?: string
    /** The date of the oldest open invoice */
    oldestOpenInvoiceDate?: string
    totalUnpaidAmountDue: Amount
    /** The total number of unpaid invoices */
    totalUnpaidInvoices: number
}

export interface InvoicesInfoResponse {
    data: InvoicesInfo
}

export interface InvoiceWithLineItems {
    invoice: Invoice
    lineItems: InvoiceLineItem[]
}

export type InvoiceStatus = (typeof InvoiceStatus)[keyof typeof InvoiceStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceStatus = {
    DRAFT: 'DRAFT',
    POSTED: 'POSTED',
    PAID: 'PAID',
    CREDITED: 'CREDITED',
    PARTIALLY_CREDITED: 'PARTIALLY_CREDITED',
    CANCELED: 'CANCELED',
    FAILED: 'FAILED',
} as const

export interface InvoiceResponse {
    data: InvoiceWithLineItems
}

export interface InvoiceLineItem {
    amountWithoutTax: Amount
    /** If this line item is a discount, this field contains the id of the line item that it is applied to */
    appliedToItemId?: string
    billingPeriod: DateInterval
    /** The description of the charge used to create the line item */
    chargeDescription: string
    /** The name of the charge used to create the line item */
    chargeName: string
    /** This can be one of ChargeType values */
    chargeType?: string
    /** The description of the line item */
    description: string
    /** The line item identifier */
    id: string
    taxAmount: Amount
}

/**
 * The amounts of the invoice including total, tax, discounts, refunds and payments.
 */
export interface InvoiceAmounts {
    remainingBalance: Amount
    tax: Amount
    total: Amount
    totalWithoutTax: Amount
}

export interface Invoice {
    amounts: InvoiceAmounts
    /** The date when the invoice has been created */
    createdDate: string
    /** The date by which the payment for this invoice is due */
    dueDate: string
    /** The invoice identifier */
    id: string
    /** The human readable identifier of an invoice */
    number: string
    /** This can be one of InvoiceStatus values */
    status: string
}

export interface InvoiceListResponse {
    cursorPageInfo: CursorPageInfo
    data: Invoice[]
}

export interface HealthModel {
    details?: HealthModelDetails
    error?: HealthModelError
    healthy: boolean
    message?: string
    time: string
}

export interface HealthResponseModel {
    data: HealthModel
}

export type HealthModelErrorSuppressedItemStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type HealthModelErrorSuppressedItem = {
    localizedMessage?: string
    message?: string
    stackTrace?: HealthModelErrorSuppressedItemStackTraceItem[]
}

export type HealthModelErrorStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type HealthModelErrorCauseStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type HealthModelErrorCause = {
    localizedMessage?: string
    message?: string
    stackTrace?: HealthModelErrorCauseStackTraceItem[]
}

export type HealthModelError = {
    cause?: HealthModelErrorCause
    localizedMessage?: string
    message?: string
    stackTrace?: HealthModelErrorStackTraceItem[]
    suppressed?: HealthModelErrorSuppressedItem[]
}

export type HealthModelDetails = {[key: string]: {[key: string]: any}}

export interface DiscountResponse {
    data: ProductRatePlan
}

export interface DeleteSubscriptionRatePlanRequest {
    /** The company to which the subscription belongs to. */
    companyId: string
    /** The rate plan ids to delete from the subscription. */
    ratePlanIds: string[]
    /** The source that triggered the subscription update */
    subscriptionUpdateSource: string
}

/**
 * The start and the end date of the trial period. Returns null if the customer didn't have trial
 */
export interface DateInterval {
    /** The end date of the period. The end date is exclusive */
    exclusiveEndDate: string
    /** The start date of the period. The start date is inclusive */
    inclusiveStartDate: string
}

export interface DataResponsePreSelectedPlatformPlanResponse {
    data: PreSelectedPlatformPlanResponse
}

export interface DataResponsePlanSelectionResponse {
    data: PlanSelectionResponse
}

export interface DataResponsePlanRecommendationResponse {
    data: PlanRecommendationResponse
}

export interface DataResponseListPreSelectedPlatformPlanResponse {
    data: PreSelectedPlatformPlanResponse[]
}

export type CursorPageCurrentRequestInfoParameters = {[key: string]: string[]}

export interface CursorPageCurrentRequestInfo {
    after?: string
    before?: string
    limit?: number
    offset?: number
    parameters: CursorPageCurrentRequestInfoParameters
    sortingKeys?: string[]
    sortingOrder?: PageOrder[]
}

export interface CursorPageInfo {
    currentRequestPagination: CursorPageCurrentRequestInfo
    endCursor?: string
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor?: string
    total?: number
}

/**
 * The current billing period for the subscription
 */
export interface CurrentBillingPeriod {
    /** The date when the last billing period started. The date is in UTC */
    lastBillingDate: string
    /** The date when the next billing period will start. The date is in UTC */
    nextBillingDate: string
}

export interface CreditNoteWithLineItems {
    creditNote: CreditNote
    lineItems: CreditNoteLineItem[]
}

export type CreditNoteStatus = (typeof CreditNoteStatus)[keyof typeof CreditNoteStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreditNoteStatus = {
    DRAFT: 'DRAFT',
    POSTED: 'POSTED',
    CANCELED: 'CANCELED',
    FAILED: 'FAILED',
} as const

export interface CreditNoteListResponse {
    cursorPageInfo: CursorPageInfo
    data: CreditNote[]
}

export interface CreditNoteLineItem {
    amount: Amount
    amountExcludingTax: Amount
    amountIncludingTax: Amount
    /** This specifies if the credit memo item amount is inclusive or exclusive of tax. */
    amountIsTaxInclusive: boolean
    /** The identifier of the invoice item this credit note item is applied to. Null for standalone credit notes */
    appliedToItemId?: string
    billingPeriod: DateInterval
    /** This can be one of ChargeType values */
    chargeType?: string
    /** Description for the line item */
    description: string
    /** The line item identifier */
    id: string
    /** The identifier of the invoice item associated with this credit note item. Null for standalone credit notes */
    invoiceItemId?: string
    /** The number of units of this item. */
    quantity?: number
    /** The unique SKU of the product associated with this item. */
    sku?: string
    /** The identifier of the subscription associated with the credit note item. Null for standalone credit notes */
    subscriptionId?: string
    /** The identifier the subscription item associated with this credit note item. Null for standalone credit notes */
    subscriptionItemId?: string
    taxAmount: Amount
}

/**
 * The amounts of the credit note including total, tax, discounts, refunds and payments.
 */
export interface CreditNoteAmounts {
    refunded: Amount
    tax: Amount
    total: Amount
    totalWithoutTax: Amount
}

export interface CreditNote {
    amounts: CreditNoteAmounts
    /** The credit note identifier */
    id: string
    /** The identifier of the invoice that this credit note is related to. */
    invoiceId?: string
    /** The date/time when the credit note has been created */
    issued: string
    /** The human readable identifier of a credit note */
    number: string
    /** This can be one of CreditNoteStatus values */
    status: string
}

export interface CreatePreSelectedPlatformPlanRequest {
    companyId: string
    planName: string
    planSelection?: PlanSelection
}

export type CpqRatePlanType = (typeof CpqRatePlanType)[keyof typeof CpqRatePlanType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CpqRatePlanType = {
    ADVANCED: 'ADVANCED',
    ESSENTIAL: 'ESSENTIAL',
    STARTER: 'STARTER',
    BEYOND: 'BEYOND',
} as const

export type CpqBillingPeriodType = (typeof CpqBillingPeriodType)[keyof typeof CpqBillingPeriodType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CpqBillingPeriodType = {
    MONTH: 'MONTH',
    YEAR: 'YEAR',
} as const

export type CompanySize = (typeof CompanySize)[keyof typeof CompanySize]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanySize = {
    SOLETRADER: 'SOLETRADER',
    SIZE1: 'SIZE1',
    SIZE2: 'SIZE2',
    SIZE5: 'SIZE5',
    SIZE10: 'SIZE10',
    SIZE50: 'SIZE50',
    SIZE100: 'SIZE100',
    SIZE250: 'SIZE250',
    SIZE500: 'SIZE500',
} as const

export interface Company {
    billingAddress: PostalAddress
    billingInfo: BillingInfo
    /** The contact email of the company. It is used for sending invoices and other notifications. */
    contactEmail?: string
    /** The company identifier */
    id: string
    /** Company name, not necessarily unique */
    name: string
    /** The id of the organization this company belongs to. It is used only for multi-entity companies. */
    organizationId?: string
    /** This can be one of OrganizationType values */
    organizationType?: string
    taxInfo: TaxInfo
}

export interface CompanyResponse {
    data: Company
}

export type ChargeableType = (typeof ChargeableType)[keyof typeof ChargeableType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChargeableType = {
    CARD: 'CARD',
    INVOICES: 'INVOICES',
    REIMBURSEMENT: 'REIMBURSEMENT',
} as const

export interface ChargeableEvent {
    /** The company identifier */
    companyId: string
    /** The timestamp when the event was created */
    createdAt: string
    /** Specifies whether event is crediting the customer or not */
    credit: boolean
    /** The employee identifier for the event */
    employeeId?: string
    /** Specifies whether event should be billed or free for the customer */
    free: boolean
    /** The chargeable event identifier */
    id: string
    /** The identifier for the invoice item event is processed in */
    invoiceItemId?: string
    monetaryAmount?: Amount
    /** Specifies quantity of the event */
    quantity: number
    /** External reference used as foreign key for certain events */
    reference?: string
    /** This can be one of ChargeableType values */
    type: string
}

export interface ChargeablesListResponse {
    cursorPageInfo: CursorPageInfo
    data: ChargeableEvent[]
}

export type ChargeType = (typeof ChargeType)[keyof typeof ChargeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChargeType = {
    RECURRING: 'RECURRING',
    USAGE: 'USAGE',
    ONE_TIME: 'ONE_TIME',
} as const

/**
 * The list of tiers for tiered charge model. If the model is not charged returns null
 */
export interface ChargeTier {
    /** Defines the end of the tiers range. The start unit is a previous tier's ending unit. May be null for the last tier */
    endingUnit?: number
    price: Amount
    /** Indicates if pricing is a flat fee or is per unit. This field is for tiered and volume pricing models only */
    priceFormat: string
    /** Identifier of the tier. Can be used to determine the tiers order. Starts from 0 */
    tier: number
}

export type ChargeModel = (typeof ChargeModel)[keyof typeof ChargeModel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChargeModel = {
    FLAT_FEE: 'FLAT_FEE',
    TIERED: 'TIERED',
    PER_UNIT: 'PER_UNIT',
    VOLUME: 'VOLUME',
    MULTI_ATTRIBUTE: 'MULTI_ATTRIBUTE',
    DISCOUNT: 'DISCOUNT',
    OVERAGE: 'OVERAGE',
} as const

export interface CardOrderCalculation {
    discount?: Amount
    price: Amount
    total: Amount
}

export interface CalculateCardOrderPriceRequest {
    /** The IDs of the employees that will receive the cards. */
    employeeIds: string[]
}

export type BillingPeriodType = (typeof BillingPeriodType)[keyof typeof BillingPeriodType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingPeriodType = {
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    YEAR: 'YEAR',
    ONE_TIME: 'ONE_TIME',
} as const

export interface BillingInformationVat {
    /** VAT id of the company. It is used for VAT calculation and reporting. */
    vatId?: string
}

export interface BillingInformationVatResponse {
    data: BillingInformationVat
}

/**
 * The currency code associated with the company. It determines the currency of the invoices and credit memos
 */
export type BillingInformationCurrency =
    (typeof BillingInformationCurrency)[keyof typeof BillingInformationCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingInformationCurrency = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

export interface BillingInformation {
    address?: PostalAddress
    /** The contact email of the company. It is used for sending invoices and other notifications. */
    contactEmail?: string
    /** The currency code associated with the company. It determines the currency of the invoices and credit memos */
    currency?: BillingInformationCurrency
    /** VAT id of the company. It is used for VAT calculation and reporting. */
    vatId?: string
}

export interface BillingInformationResponse {
    data: BillingInformation
}

/**
 * The currency code associated with the company. It determines the currency of the invoices and credit memos
 */
export type BillingInfoCurrency = (typeof BillingInfoCurrency)[keyof typeof BillingInfoCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingInfoCurrency = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

/**
 * The billing information of the company, such as currency and bill cycle day.
 */
export interface BillingInfo {
    /** The day of the month on which the company is billed and invoices are generated. Returns null if the company has no subscription */
    billCycleDay?: number
    /** The currency code associated with the company. It determines the currency of the invoices and credit memos */
    currency: BillingInfoCurrency
}

/**
 * The code corresponding to the currency
 */
export type AmountCurrency = (typeof AmountCurrency)[keyof typeof AmountCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AmountCurrency = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

/**
 * The price of the tier if the charge is a flat fee, or the price of each unit in the tier if the charge model is tiered pricing
 */
export interface Amount {
    /** The code corresponding to the currency */
    currency: AmountCurrency
    /** The amount in minor units */
    value: number
}

export interface AddSubscriptionRatePlansRequest {
    /** The IDs of rate plans to add */
    addonRatePlanIds: string[]
    /** The company ID */
    companyId: string
    /** The source that triggered the subscription update */
    subscriptionUpdateSource: string
}

export interface AddDiscountToSubscriptionRequest {
    /** The duration in months for the discount. If not provided, the discount will be applied based on the default rate plan configuration */
    durationInMonths?: number
    /** The percentage of the discount */
    percent: number
    /** The id of the product rate plan that will be added to the subscription. It defines to which rate plans the discount will be applied. */
    productRatePlanId: string
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P,
) => any
    ? P
    : never

/**
 * @summary Returns the health of the service
 */
export const check = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<HealthResponseModel>({url: `/health`, method: 'get'}, options)
}

/**
 * @summary Update company billing information
 */
export const updateCompanyBillingInformation = (
    updateCompanyBillingInformationRequest: UpdateCompanyBillingInformationRequest,
    params: UpdateCompanyBillingInformationParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<BillingInformationResponse>(
        {
            url: `/v1/billing-information`,
            method: 'put',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            data: updateCompanyBillingInformationRequest,
            params,
        },
        options,
    )
}

/**
 * @summary Get company billing vat id
 */
export const getCompanyVatId = (
    params: GetCompanyVatIdParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<BillingInformationVatResponse>(
        {url: `/v1/billing-information/vat-id`, method: 'get', params},
        options,
    )
}

/**
 * @summary Update company billing vat id
 */
export const updateCompanyVatId = (
    updateCompanyVatRequest: UpdateCompanyVatRequest,
    params: UpdateCompanyVatIdParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<BillingInformationResponse>(
        {
            url: `/v1/billing-information/vat-id`,
            method: 'put',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            data: updateCompanyVatRequest,
            params,
        },
        options,
    )
}

/**
 * @summary Calculate card order price
 */
export const calculateCardOrderPrice = (
    calculateCardOrderPriceRequest: CalculateCardOrderPriceRequest,
    params: CalculateCardOrderPriceParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<CardOrderCalculation>(
        {
            url: `/v1/cardorders/calculate-price`,
            method: 'post',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            data: calculateCardOrderPriceRequest,
            params,
        },
        options,
    )
}

/**
 * @summary List chargeables
 */
export const listChargeables = (
    params: ListChargeablesParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<ChargeablesListResponse>(
        {url: `/v1/chargeables`, method: 'get', params},
        options,
    )
}

/**
 * @summary Get company information
 */
export const getCompany = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<CompanyResponse>({url: `/v1/companies/${companyId}`, method: 'get'}, options)
}

/**
 * This operation lists available addons for a company, filtering outand validating based product rate plans based on its current subscription.
 * @summary Get a list of the available addons for a company in its current subscription
 */
export const getAvailableUpsellAddons = (
    params: GetAvailableUpsellAddonsParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<RatePlanListResponse>(
        {url: `/v1/cpq/available-addons`, method: 'get', params},
        options,
    )
}

/**
 * @summary Estimate the price change corresponding to a subscription update or creation
 */
export const estimateSubscriptionPriceChange = (
    subscriptionChangeRequest: SubscriptionChangeRequest,
    params: EstimateSubscriptionPriceChangeParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<SubscriptionPriceEstimationResponse>(
        {
            url: `/v1/cpq/estimate-subscription-price-change`,
            method: 'post',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            data: subscriptionChangeRequest,
            params,
        },
        options,
    )
}

/**
 * @deprecated
 * @summary Get a list of Products available in given country
 */
export const listCpqProducts = (
    params: ListCpqProductsParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<ProductListResponse>(
        {url: `/v1/cpq/products`, method: 'get', params},
        options,
    )
}

/**
 * @deprecated
 * @summary Get recommended plan
 */
export const computeRecommendedPlan = (
    planRecommendationRequest: PlanRecommendationRequest,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<DataResponsePlanRecommendationResponse>(
        {
            url: `/v1/cpq/recommended-plan`,
            method: 'post',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            data: planRecommendationRequest,
        },
        options,
    )
}

/**
 * This operation removes rate plans from a subscription. The subscription is identified by the company id and the rate plans are identified by their ids.
 * @summary Remove rate plans from a subscription
 */
export const deleteSubscriptionRatePlans = (
    deleteSubscriptionRatePlanRequest: DeleteSubscriptionRatePlanRequest,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<SubscriptionResponse>(
        {
            url: `/v1/cpq/subscription-rate-plans`,
            method: 'delete',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            data: deleteSubscriptionRatePlanRequest,
        },
        options,
    )
}

/**
 * @summary Update a subscription
 */
export const addRatePlans = (
    addSubscriptionRatePlansRequest: AddSubscriptionRatePlansRequest,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<SubscriptionResponse>(
        {
            url: `/v1/cpq/subscription-rate-plans`,
            method: 'put',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            data: addSubscriptionRatePlansRequest,
        },
        options,
    )
}

/**
 * @summary Update a subscription
 */
export const updateSubscription = (
    subscriptionChangeRequest: SubscriptionChangeRequest,
    params: UpdateSubscriptionParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<SubscriptionResponse>(
        {
            url: `/v1/cpq/subscriptions`,
            method: 'put',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            data: subscriptionChangeRequest,
            params,
        },
        options,
    )
}

/**
 * @summary Fetches all credit notes for a given company
 */
export const listCreditNotes = (
    params: ListCreditNotesParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<CreditNoteListResponse>(
        {url: `/v1/credit-notes`, method: 'get', params},
        options,
    )
}

/**
 * @summary Fetches a given credit note
 */
export const getCreditNote = (
    creditNoteId: string,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<CreditNoteWithLineItems>(
        {url: `/v1/credit-notes/${creditNoteId}`, method: 'get'},
        options,
    )
}

/**
 * @summary Download the credit note's PDF
 */
export const getCreditNotePDF = (
    creditNoteId: string,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<Blob>(
        {url: `/v1/credit-notes/${creditNoteId}/pdf`, method: 'get', responseType: 'blob'},
        options,
    )
}

/**
 * @summary Get Partner Discount
 */
export const getPartnerDiscounts = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DiscountResponse>(
        {url: `/v1/discounts/partner-discounts`, method: 'get'},
        options,
    )
}

/**
 * @summary Get a list of invoices by company
 */
export const listInvoices = (
    params: ListInvoicesParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<InvoiceListResponse>({url: `/v1/invoices`, method: 'get', params}, options)
}

/**
 * @summary Get company invoices information
 */
export const getCompanyInvoicesInfo = (
    params: GetCompanyInvoicesInfoParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<InvoicesInfoResponse>(
        {url: `/v1/invoices-info`, method: 'get', params},
        options,
    )
}

/**
 * @summary Get an invoices by id
 */
export const getInvoice = (invoiceId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<InvoiceResponse>({url: `/v1/invoices/${invoiceId}`, method: 'get'}, options)
}

/**
 * @summary Get an invoices pdf by id
 */
export const getInvoicePdf = (invoiceId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<Blob>(
        {url: `/v1/invoices/${invoiceId}/pdf`, method: 'get', responseType: 'blob'},
        options,
    )
}

/**
 * @summary Get the selected plan and the recommended plan for a company
 */
export const getPlanSelection = (
    params: GetPlanSelectionParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<DataResponsePlanSelectionResponse>(
        {url: `/v1/plan-selection`, method: 'get', params},
        options,
    )
}

/**
 * @summary Get pre-selected platform plans
 */
export const getPreSelectedPlatformPlan = (
    params: GetPreSelectedPlatformPlanParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<DataResponseListPreSelectedPlatformPlanResponse>(
        {url: `/v1/pre-selected-platform-plans`, method: 'get', params},
        options,
    )
}

/**
 * @summary Create pre-selected platform plan
 */
export const createPreSelectedPlatformPlan = (
    createPreSelectedPlatformPlanRequest: CreatePreSelectedPlatformPlanRequest,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<DataResponsePreSelectedPlatformPlanResponse>(
        {
            url: `/v1/pre-selected-platform-plans`,
            method: 'post',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            data: createPreSelectedPlatformPlanRequest,
        },
        options,
    )
}

/**
 * @summary Get a list of all Products
 */
export const listProducts = (
    params: ListProductsParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<ProductListResponse>({url: `/v1/products`, method: 'get', params}, options)
}

/**
 * @summary Get a Product
 */
export const getProduct = (productId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<ProductResponse>({url: `/v1/products/${productId}`, method: 'get'}, options)
}

/**
 * @summary List all active company subscriptions
 */
export const listSubscriptions = (
    params: ListSubscriptionsParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<SubscriptionListResponse>(
        {url: `/v1/subscriptions`, method: 'get', params},
        options,
    )
}

/**
 * @summary Cancel a subscription
 */
export const cancelSubscription = (
    subscriptionId: string,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<unknown>(
        {url: `/v1/subscriptions/${subscriptionId}`, method: 'delete'},
        options,
    )
}

/**
 * @summary Add discount to a subscription
 */
export const addDiscountToSubscription = (
    subscriptionId: string,
    addDiscountToSubscriptionRequest: AddDiscountToSubscriptionRequest,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<SubscriptionResponse>(
        {
            url: `/v1/subscriptions/${subscriptionId}/discounts`,
            method: 'post',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            data: addDiscountToSubscriptionRequest,
        },
        options,
    )
}

/**
 * @summary Delete discount from a subscription
 */
export const deleteDiscountFromSubscription = (
    subscriptionId: string,
    discountId: string,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<SubscriptionResponse>(
        {url: `/v1/subscriptions/${subscriptionId}/discounts/${discountId}`, method: 'delete'},
        options,
    )
}

/**
 * @summary Estimate the price of a subscription
 */
export const estimateSubscriptionPrice = (
    subscriptionId: string,
    params: EstimateSubscriptionPriceParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<SubscriptionPriceEstimationResponse>(
        {url: `/v1/subscriptions/${subscriptionId}/estimate-price`, method: 'get', params},
        options,
    )
}

/**
 * @summary Get a list of all wallet payments for a company
 */
export const listWalletPayments = (
    params: ListWalletPaymentsParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<WalletPaymentListResponse>(
        {url: `/v1/wallet-payments`, method: 'get', params},
        options,
    )
}

/**
 * @summary Get a wallet payment by id
 */
export const getWalletPayment = (
    walletPaymentId: string,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<WalletPaymentResponse>(
        {url: `/v1/wallet-payments/${walletPaymentId}`, method: 'get'},
        options,
    )
}

/**
 * @summary Get a list of all wallet refunds for a company
 */
export const listWalletRefunds = (
    params: ListWalletRefundsParams,
    options: SecondParameter<typeof moonRequest>,
) => {
    return moonRequest<WalletRefundListResponse>(
        {url: `/v1/wallet-refunds`, method: 'get', params},
        options,
    )
}

/**
 * @summary Get a wallet refund for a company by id
 */
export const getWalletRefund = (refundId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<WalletRefundResponse>(
        {url: `/v1/wallet-refunds/${refundId}`, method: 'get'},
        options,
    )
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export type CheckResult = NonNullable<Awaited<ReturnType<typeof check>>>
export type UpdateCompanyBillingInformationResult = NonNullable<
    Awaited<ReturnType<typeof updateCompanyBillingInformation>>
>
export type GetCompanyVatIdResult = NonNullable<Awaited<ReturnType<typeof getCompanyVatId>>>
export type UpdateCompanyVatIdResult = NonNullable<Awaited<ReturnType<typeof updateCompanyVatId>>>
export type CalculateCardOrderPriceResult = NonNullable<
    Awaited<ReturnType<typeof calculateCardOrderPrice>>
>
export type ListChargeablesResult = NonNullable<Awaited<ReturnType<typeof listChargeables>>>
export type GetCompanyResult = NonNullable<Awaited<ReturnType<typeof getCompany>>>
export type GetAvailableUpsellAddonsResult = NonNullable<
    Awaited<ReturnType<typeof getAvailableUpsellAddons>>
>
export type EstimateSubscriptionPriceChangeResult = NonNullable<
    Awaited<ReturnType<typeof estimateSubscriptionPriceChange>>
>
export type ListCpqProductsResult = NonNullable<Awaited<ReturnType<typeof listCpqProducts>>>
export type ComputeRecommendedPlanResult = NonNullable<
    Awaited<ReturnType<typeof computeRecommendedPlan>>
>
export type DeleteSubscriptionRatePlansResult = NonNullable<
    Awaited<ReturnType<typeof deleteSubscriptionRatePlans>>
>
export type AddRatePlansResult = NonNullable<Awaited<ReturnType<typeof addRatePlans>>>
export type UpdateSubscriptionResult = NonNullable<Awaited<ReturnType<typeof updateSubscription>>>
export type ListCreditNotesResult = NonNullable<Awaited<ReturnType<typeof listCreditNotes>>>
export type GetCreditNoteResult = NonNullable<Awaited<ReturnType<typeof getCreditNote>>>
export type GetCreditNotePDFResult = NonNullable<Awaited<ReturnType<typeof getCreditNotePDF>>>
export type GetPartnerDiscountsResult = NonNullable<Awaited<ReturnType<typeof getPartnerDiscounts>>>
export type ListInvoicesResult = NonNullable<Awaited<ReturnType<typeof listInvoices>>>
export type GetCompanyInvoicesInfoResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyInvoicesInfo>>
>
export type GetInvoiceResult = NonNullable<Awaited<ReturnType<typeof getInvoice>>>
export type GetInvoicePdfResult = NonNullable<Awaited<ReturnType<typeof getInvoicePdf>>>
export type GetPlanSelectionResult = NonNullable<Awaited<ReturnType<typeof getPlanSelection>>>
export type GetPreSelectedPlatformPlanResult = NonNullable<
    Awaited<ReturnType<typeof getPreSelectedPlatformPlan>>
>
export type CreatePreSelectedPlatformPlanResult = NonNullable<
    Awaited<ReturnType<typeof createPreSelectedPlatformPlan>>
>
export type ListProductsResult = NonNullable<Awaited<ReturnType<typeof listProducts>>>
export type GetProductResult = NonNullable<Awaited<ReturnType<typeof getProduct>>>
export type ListSubscriptionsResult = NonNullable<Awaited<ReturnType<typeof listSubscriptions>>>
export type CancelSubscriptionResult = NonNullable<Awaited<ReturnType<typeof cancelSubscription>>>
export type AddDiscountToSubscriptionResult = NonNullable<
    Awaited<ReturnType<typeof addDiscountToSubscription>>
>
export type DeleteDiscountFromSubscriptionResult = NonNullable<
    Awaited<ReturnType<typeof deleteDiscountFromSubscription>>
>
export type EstimateSubscriptionPriceResult = NonNullable<
    Awaited<ReturnType<typeof estimateSubscriptionPrice>>
>
export type ListWalletPaymentsResult = NonNullable<Awaited<ReturnType<typeof listWalletPayments>>>
export type GetWalletPaymentResult = NonNullable<Awaited<ReturnType<typeof getWalletPayment>>>
export type ListWalletRefundsResult = NonNullable<Awaited<ReturnType<typeof listWalletRefunds>>>
export type GetWalletRefundResult = NonNullable<Awaited<ReturnType<typeof getWalletRefund>>>
